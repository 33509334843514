import React, { useState, useEffect } from 'react'
import { Card, Col, Row, Form, Table, Button, Image, Modal, Dropdown, FormControl } from 'react-bootstrap'
import './Dashboard.scss'
import SideBar from './SideBar';
import { Link } from "react-router-dom";

import Header from './Header';
import TableJs from './Table';
import Book from '../../assets/img/book.png'
import Due from '../../assets/img/due-date.png'
import Percentage from '../../assets/img/discount.png'
import Income from '../../assets/img/salary.png'
import loaderImage from '../../assets/img/logo.png'
import DefaultImage from '../../assets/img/default.png'

// auth imports
import jwt_decode from "jwt-decode";
import { serverUrl } from "../../serverUrl";
import { toast } from 'react-toastify';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';

// format the number so that you can get shorted versions of it
const formatNumber = (number) => {
  if (number >= 1000) {
    const roundedNumber = Math.floor(number / 1000);
    return `${roundedNumber}k`;
  }
  return number;
};


function Dashboard() {
  const [showSidebar, setShowSidebar] = useState(true);

  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }
  // loading status
  const [isLoading, setIsLoading] = useState(true)
  // apartments of the landlord
  const [landlordApartment, setLandlordApartment] = useState([])
  // counted house
  const [occupiedCount, setOccupiedCount] = useState({});
  const [incomeCount, setIncomeCount] = useState({});
  const [defaultersCount, setDefaultersCount] = useState({});
  // generarl defaulters
  const [defaulters, setDefaulters] = useState('');
  const [defaultersApartment, setDefaultersApartment] = useState({});
  const [monthIncome, setMonthIncome] = useState(0);
  const [rentIncome, setRentIncome] = useState(0);
  const [utilityIncome, setUtilityIncome] = useState(0);

  const [allDefaulters, setAllDefaulters] = useState(0);

  const [previousmonthIncome, setPreviousMonthIncome] = useState(0);

  const [landlordIncomeDue, setLandlordIncomeDue] = useState(0);
  const [landlordPreviousOccupancy, setLandlordPreviousOccupancy] = useState(0);
  const [landlordCurrentOccupancy, setLandlordCurrentOccupancy] = useState(0);
  const [landlordPercentagePaid, setLandlordPercentagePaid] = useState(0);
  const [selectedApartmentImage, setSelectedApartmentImage] = useState({});

  // for calculations purposes
  const [monthIncomeOldSchool, setMonthIncomeOldSchool] = useState(0);
  const [previousmonthIncomeOldSchool, setPreviousMonthIncomeOldSchool] = useState(0);

  // modal to be used in filtering on the month
  const [addModal, setAddModal] = useState(false)

  // rent apartment table metrics
  const [rentPaidApartment, setRentPaidApartment] = useState('')
  const [rentPercentageApartment, setRentPercentageApartment] = useState('')


  // modal for filtering on the month
  const handleMonth = () => {
    setAddModal(true);
  };

  const handleMonthClose = () => {
    setAddModal(false);
  };
  const handleCloseModalReload = () => {
    setAddModal(false);
    window.location.reload()
  };

  // get the current month so that i can filter out the total income
  const months = Array.from({ length: 12 }, (_, i) => {
    const month = new Date(new Date().getFullYear(), i, 1);
    return month.toLocaleString('default', { month: 'long' });
  });
  // Get the current month automatically
  const currentDate = new Date();
  const currentMonthIndex = new Date().getMonth();
  const currentMonth = months[currentMonthIndex];

  // Get the previous month automatically
  const previousMonthIndex = currentMonthIndex === 0 ? 11 : currentMonthIndex - 1;
  const previousMonth = months[previousMonthIndex];

  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedYear, setSelectedYear] = useState("");

  // get the months till today
  const monthsTillToday = Array.from({ length: currentMonthIndex + 1 }, (_, i) => {
    const month = new Date(currentDate.getFullYear(), i, 1);
    return month.toLocaleString('default', { month: 'long' });
  });

  // get the years
  const currentYear = new Date().getFullYear();
  const startYear = currentYear;

  const years = Array.from({ length: 21 }, (_, i) => startYear - i);


  // display 12  per page
  const [currentYearPage, setCurrentYearPage] = useState(0);
  const itemsPerPageYear = 12;
  const totalPagesYear = Math.ceil(years.length / itemsPerPageYear);

  const handlePrevYearPage = () => {
    setCurrentYearPage(currentYearPage - 1);
  };

  const handleNextYearPage = () => {
    setCurrentYearPage(currentYearPage + 1);
  };

  const startIndexYear = currentYearPage * itemsPerPageYear;
  const endIndexYear = startIndexYear + itemsPerPageYear;
  const displayedYears = years.slice(startIndexYear, endIndexYear);


  // get the months and years filtered out
  const [monthFilterActive, setMonthFilterActive] = useState("")
  const [yearFilterActive, setYearFilterActive] = useState("")

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setShowModal(true);
  };

  const handleCloseModal = () => {

    setShowModal(false);
  };

  const [getMonthSelect, setGetMonth] = useState("")
  const [selectedMonth, setSelectedMonth] = useState("");

  const handleMonthButtonClick = (month) => {
    setSelectedMonth(month);
    localStorage.setItem("month", month);
    setShowModal(false);
    window.location.reload();

  };

  // set the year filtered in the local storage
  const handleYearButtonClick = (year) => {
    setSelectedYear(year);

    localStorage.setItem("yearRentIncomeDashboard", year);
    setShowModal(false);
    window.location.reload();

  };
  const apartmentImage = {};



  // from the localstorage get the logged in user
  useEffect(() => {
    setIsLoading(true)

    const accessToken = localStorage.getItem("authTokens");
    // get the access token
    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;


    // headers access token
    const config = {
      headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwt_decode(accessToken);
    const userId = decodedToken.user_id;



    // get the landlord details that are linked to the user
    serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
      .then((res) => {
        // get the landlord Id
        const landlordIds = res.data.results.map((landlord) => landlord.id);
        // get the total income of the properties in the previous month
        let selectedMonthModalPrevious = localStorage.getItem('month');
        setMonthFilterActive(selectedMonthModalPrevious)
        let previousMonth;

        if (selectedMonthModalPrevious) {
          const selectedMonthDate = new Date(selectedMonthModalPrevious);

          // Check if the selected month is a valid date
          if (!isNaN(selectedMonthDate)) {
            selectedMonthDate.setMonth(selectedMonthDate.getMonth() - 1);
            previousMonth = selectedMonthDate.toLocaleString('default', { month: 'long' });
          } else {
            // Invalid date, fallback to the previous month of the current date
            const currentMonthDate = new Date();
            currentMonthDate.setMonth(currentMonthDate.getMonth() - 1);
            previousMonth = currentMonthDate.toLocaleString('default', { month: 'long' });
          }
        } else {
          // No selected month, fallback to the previous month of the current date
          const currentMonthDate = new Date();
          currentMonthDate.setMonth(currentMonthDate.getMonth() - 1);
          previousMonth = currentMonthDate.toLocaleString('default', { month: 'long' });
        }

        serverUrl
          .get(`/landlord/react-rent-expeses-landlord/?landlord_id=${landlordIds}&month=${encodeURIComponent(previousMonth)}`, config)
          .then((res) => {
            setPreviousMonthIncomeOldSchool(res.data.total_rent_income);
            const formattedIncomePrevious = formatNumber(res.data.total_rent_income);
            setPreviousMonthIncome(formattedIncomePrevious);
          })
          .catch((error) => {
            console.log(error);
          });



        // set to get the month filtered if not then display the current month
        let selectedMonthModal = localStorage.getItem('month');
        if (!selectedMonthModal) {
          selectedMonthModal = currentMonth;
        }
        // get the total income of the properties in the current month
        serverUrl.get(`/landlord/react-rent-expeses-landlord/?landlord_id=${landlordIds}&month=${selectedMonthModal}&year=${currentYear}`, config)
          .then((res) => {
            setMonthIncomeOldSchool(res.data.total_rent_income);
            const income = res.data.total_rent_income;
            const rent_total = res.data.rent_total;
            const utilities_total = res.data.utilites_total;

            const utilityTotal = formatNumber(utilities_total)
            const rentTotal = formatNumber(rent_total)
            // formated Income
            // const formattedIncome = formatNumber(income);
            const formattedIncome = income;
            setMonthIncome(income)
            setRentIncome(rentTotal);
            setUtilityIncome(utilityTotal)

          })
          .catch((error) => {
            console.log(error);
          });

        // get the total number of defaulters in the properties
        serverUrl.get(`/landlord/get_defaulters_list/?landlord_id=${landlordIds}`, config)
          .then((res) => {
            setDefaulters(res.data.defaulters)
          })
          .catch((error) => {
            console.log(error)
          })
        // get the occupancy percrentage
        serverUrl.get(`/landlord/get_rent_summary/?landlord_id=${landlordIds}&month=${selectedMonthModal}&year=${currentYear}`, config)
          .then((res) => {
            setLandlordPreviousOccupancy(res.data.previous_occupancy_percentage)
            setLandlordCurrentOccupancy(res.data.occupancy_percentage)
            // formated rentDue
            // const formattedRentDue = formatNumber(res.data.rent_due)
            const formattedRentDue = res.data.rent_due

            setLandlordIncomeDue(formattedRentDue)
            // setMonthIncome(res.data.total_income);

            setLandlordPercentagePaid(res.data.percentage_paid)
          })
          .catch((error) => {
            console.log(error)
          })
        // using the landlord id get the apartments linked to the landlord
        const landlordApartmentPromises = landlordIds.map((landlordId) => {
          // return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
          //   .then((res) => res.data.results)
          //   .catch((error) => {
          //     console.log(error);
          //     return [];
          //   });
          return serverUrl.get(`/users/list-user-groups/?user_id=${userId}`, config)
          .then((res) => {
            const userGroups = res.data;
            let landlordApartmentPromises;
        
            const superAdminGroup = userGroups.find((group) => group.name === 'Super Admin');
            if (superAdminGroup) {
              landlordApartmentPromises = serverUrl.get(`/landlord/apartment`, config)
                .then((res) => {
                  return res.data.results.filter(
                    (apartment) => apartment.for_kodi === false && apartment.is_visible === true
                  );
                })
                .catch((error) => {
                  console.log(error);
                  return [];
                });
            } else {
              landlordApartmentPromises = Promise.all(landlordIds.map((landlordId) => {
                return serverUrl.get(`/landlord/landlord-apartment_name/?landlord_id=${landlordId}`, config)
                  .then((res) => res.data.results.filter(
                    (apartment) => apartment.for_kodi === false && apartment.is_visible === true
                  ))
                  .catch((error) => {
                    console.log(error);
                    return [];
                  });
              }));
            }
        
            return landlordApartmentPromises;
          })
          .catch((error) => {
            console.log(error);
            return [];
          });

        });

      
      
     
        Promise.all(landlordApartmentPromises)
          .then((results) => {
            // i am merging the sub-arrays into a new array
            const apartments = results.flat();
            setLandlordApartment(apartments.filter(
              (apartment) => apartment.for_kodi === false && apartment.is_visible === true
            ));
            // get the rent paid and occupancy amount per apartment

            const apartmentPromises = apartments.map((apartment) => {
              return serverUrl.get(`/landlord/get_rent_summary/?apartment_id=${apartment.id}&month=${selectedMonthModal}&year=${currentYear}`, config)
                .then((res) => ({ apartmentId: apartment.id, dataSummary: res.data }))
                .catch((error) => {
                  console.log(error);
                  return { apartmentId: apartment.id, dataSummary: res.data };
                });

            })

            // get the images of the apartments based on the apartment id
            apartments.map((apartment) => {
              serverUrl
                .get(
                  `/landlord/apartment-images/?apartment_id=${apartment.id}`,
                  config
                )
                .then((res) => {
                  res.data.results.forEach((res) => {
                    apartmentImage[res.apartment] = res.image;
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            });
            setSelectedApartmentImage(apartmentImage);
            setIsLoading(false);

            // set count of the number of houses that are in that apartment
            Promise.all(apartmentPromises)
              .then((results) => {

                // object that gets the number of apartments
                const apartmentRentPaid = {};
                const apartmentOccupancyPercentage = {};
                const apartmentRentPaidExpected = {};
                const apartmentIncome = {};


                results.forEach(({ apartmentId, dataSummary }) => {
                  apartmentRentPaid[apartmentId] = dataSummary.rent_paid;
                  apartmentOccupancyPercentage[apartmentId] = dataSummary.occupancy_percentage;
                  apartmentRentPaidExpected[apartmentId] = dataSummary.total_rent;

                });
                setRentPaidApartment(apartmentRentPaid);
                setRentPercentageApartment(apartmentOccupancyPercentage)
                setIncomeCount(apartmentRentPaidExpected)
                setIsLoading(false)

              })
              .catch((error) => {
                console.log(error);
                setIsLoading(false)

              });

            const houseOccupancyPromises = apartments.map((apartment) => {
              return serverUrl.get(`/landlord/display-occupied-houses-in-expenses/?apartment_id=${apartment.id}`, config)
                .then((res) => ({ apartmentId: apartment.id, count: res.data.count }))
                .catch((error) => {
                  console.log(error);
                  return { apartmentId: apartment.id, count: 0 };
                  setIsLoading(false)

                });
            });
            // set count of the number of houses that are occupied in that apartment
            Promise.all(houseOccupancyPromises)
              .then((results) => {

                // object that gets the number of apartments
                const houseOccupiedObj = {};
                results.forEach(({ apartmentId, count }) => {
                  houseOccupiedObj[apartmentId] = count;
                });
                setOccupiedCount(houseOccupiedObj);
                setIsLoading(false)

              })
              .catch((error) => {
                console.log(error);
              });

            // get the income monthly
            const houseIncomePromises = apartments.map((apartment) => {
              return serverUrl.get(`/tenant/expected-rental-income-by-month/?apartment_id=${apartment.id}`, config)
                .then((res) => ({ apartmentId: apartment.id, count: res.data.total_rental_income }))
                .catch((error) => {
                  console.log(error);
                  return { apartmentId: apartment.id, count: 0 };
                });
            });
            // set count of the number of houses that are occupied in that apartment
            Promise.all(houseIncomePromises)
              .then((results) => {
                // object that gets the number of apartments
                const houseIncomeObj = {};
                results.forEach(({ apartmentId, count }) => {
                  houseIncomeObj[apartmentId] = count;
                });
                // setIncomeCount(houseIncomeObj);
                setIsLoading(false)

              })
              .catch((error) => {
                console.log(error);
              });

            const houseDefaultersPromises = apartments.map((apartment) => {
              return serverUrl.get(`/landlord/house/?apartment_id=${apartment.id}`, config)
                .then((res) => {
                  const defaultersPromise = res.data.results.map((defaulter) => {
                    const apartmentId = defaulter.apartment;
                    const due = defaulter.due_date;
                    return { apartmentId, due };
                  });
                  return defaultersPromise; // Return the value of defaultersPromise
                })
                .catch((error) => {
                  console.log(error);
                  return []; // Return an empty array as a fallback
                });
            });


            const defaultersCount = {};

            // set count of the number of houses that have defaulters in that apartment
            Promise.all(houseDefaultersPromises)
              .then((results) => {
                results.forEach((apartmentDefaulters) => {
                  apartmentDefaulters.forEach((defaulter) => { // Loop through each defaulter in the apartmentDefaulters array
                    const apartmentId = defaulter.apartmentId;
                    const today = new Date();
                    const dueDate = new Date(defaulter.due);
                    const defaulterCount = dueDate < today ? 1 : 0; // Check if the due date is less than today
                    if (defaultersCount.hasOwnProperty(apartmentId)) {
                      defaultersCount[apartmentId] += defaulterCount; // Add the count to the existing count for the apartment
                    } else {
                      defaultersCount[apartmentId] = defaulterCount; // Initialize the count for the apartment
                    }
                  });
                });

                setDefaultersCount(defaultersCount);
              })

            // get the defaulters in the each apartment
            let totalAmount = 0;

            const defaulterApartment = {}
            apartments.map((defaultersApartment) => {
              serverUrl.get(`/landlord/get_defaulters_list/?apartment_id=${defaultersApartment.id}`)
                .then((res) => {
                  defaulterApartment[defaultersApartment.id] = res.data.defaulters;
                  setDefaultersApartment(defaulterApartment);

                  // Calculate the total amount
                  // const defaulterAmounts = res.data.defaulters.map((defaulter) => defaulter.amount);
                  const defaulterAmounts = res.data.defaulters;

                  // const apartmentTotalAmount = defaulterAmounts.reduce((sum, amount) => sum + amount, 0);
                  const apartmentTotalAmount = defaulterAmounts

                  totalAmount += apartmentTotalAmount;

                  // You may want to do something with the total amount here
                  setAllDefaulters(totalAmount);
                })
                .catch((error) => {
                  console.log(error)
                })

            })

              .catch((error) => {
                console.log(error);
              });


          })
          .catch((error) => {
            console.log(error);
          });




          

      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  // randomise for each apartment
  const [backgroundImageApartment, setBackgroundImageApartment] = useState('');
  useEffect(() => {
    const getRandomImage = () => {

      const usedImages = Object.values(backgroundImageApartment);
      const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
      const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
      const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
      const imageUrl = `https://kodinyumba.app/media/Stock/Property/apartment${randomNumber}.jpg`;
      return imageUrl;
    };

    const updateBackgroundImages = () => {
      setIsLoading(true);

      const updatedImages = { ...backgroundImageApartment };

      // Loop through each house and update the image if not set
      for (const unitDetail of landlordApartment) {
        const apartmentId = unitDetail.id;
        if (!updatedImages[apartmentId]) {
          updatedImages[apartmentId] = getRandomImage();
        }
      }
      setBackgroundImageApartment(updatedImages);
      setIsLoading(false);
    };

    updateBackgroundImages();
  }, [landlordApartment]);

  // Perform the subtraction and determine the result for the income monthly
  const roundedResult = ((monthIncomeOldSchool - previousmonthIncomeOldSchool) / monthIncomeOldSchool) * 100;

  let result = Math.round(landlordPercentagePaid);

  // Check if the result is infinity and assign 0 if true
  if (!isFinite(result)) {
    result = 0;
  }


  // Determine the text color based on the result on the rent income ps it includes the expenses and the rent paid from the tenanat invoice
  let textColorClass = '';
  let sign = '';
  if (result > 0) {
    textColorClass = 'text-success';
    sign = '+';
  } else if (result < 0) {
    textColorClass = 'text-danger';
    sign = '-';
  }
  else if (result == 0) {
    textColorClass = 'text-success';
  }


  // Perform the subtraction and determine the result for the occupancy in each month
  let resultOccupancy = (landlordCurrentOccupancy - landlordPreviousOccupancy);

  // Check if the result is infinity and assign 0 if true
  if (!isFinite(resultOccupancy)) {
    resultOccupancy = 0;
  }


  // Determine the text color based on the result on the rent income ps it includes the expenses and the rent paid from the tenanat invoice
  let textColorClassOccupancy = '';
  let signOccupancy = '';
  if (resultOccupancy > 0) {
    textColorClassOccupancy = 'text-success';
    signOccupancy = '+';
  } else if (resultOccupancy < 0) {
    textColorClassOccupancy = 'text-danger';
    signOccupancy = '-';
  }
  else if (resultOccupancy == 0) {
    textColorClassOccupancy = 'text-success';
  }

  // send the reminders from the table that is for every apartment
  function handleSendReminder(apartmentId) {

    try {
      serverUrl.get(`/landlord/send_payment_reminder/?apartment_id=${apartmentId}`)
        .then((res) => {
          toast.success("Reminder Sent Successfully!")
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {

    }
  }
  // send the reminder for all the apartments that the landlord
  const handleSendRemindersGeneral = () => {
    try {

      const accessToken = localStorage.getItem("authTokens");
      // get the access token
      const parsedTokens = JSON.parse(accessToken);
      const access = parsedTokens.access;


      // headers access token
      const config = {
        headers: { Authorization: `Bearer ${access}` }
      }
      // decoding the token so that i can get the user id
      const decodedToken = jwt_decode(accessToken);
      const userId = decodedToken.user_id;

      // get the landlord details that are linked to the user
      serverUrl.get(`/landlord/landlord/?user_id=${userId}`, config)
        .then((res) => {
          // get the landlord Id
          const landlordIds = res.data.results.map((landlord) => landlord.id);
          // get the total income of the properties in the previous month
          serverUrl.get(`/landlord/send_payment_reminder/?landlord_id=${landlordIds}`, config)
            .then((res) => {
              toast.success("Reminder Sent Successfully To All Tenants In Your Properties!")

            })
            .catch((error) => {
              console.log(error)
            })


        })
        .catch((error) => {
          console.log(error);
        });

    } catch (error) {

    }
  };

  // send reminder for the landlord
  const [remindersModal, setRemindersModal] = useState(false)

  // modal for filtering on the month
  const handleReminderModalOpen = () => {
    setRemindersModal(true);
  };

  const handleReminderModalClose = () => {
    setRemindersModal(false);
  };
  const handleReminderModalReload = () => {
    setRemindersModal(false);
    window.location.reload()
  };

  const [selectedOptionReminder, setSelectedOptionReminder] = useState(null);
  const [addTemplate, setAddTemplate] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOptionReminder(option);
    setAddTemplate(false)
  };

  const handleAddTemplate = () => {
    setSelectedOptionReminder('')
    setAddTemplate(true)
  }




  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 100;
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [sortType, setSortType] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setSelectedRow(null); // Reset selected row when user starts a new search
  };

  const totalPages = Math.ceil(landlordApartment.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = currentPage === totalPages - 1 ? landlordApartment.length : startIndex + itemsPerPage;


  const sortedData = landlordApartment.sort((a, b) => {
    const sortValueA = typeof a[sortType] === "string" ? a[sortType].toLowerCase() : a[sortType];
    const sortValueB = typeof b[sortType] === "string" ? b[sortType].toLowerCase() : b[sortType];
    if (sortOrder === "asc") {
      return sortValueA > sortValueB ? 1 : -1;
    } else {
      return sortValueA < sortValueB ? 1 : -1;
    }
  });

  const filteredData = sortedData.filter((item) => {
    const searchableFields = Object.values(item).join(" ").toLowerCase();
    return searchableFields.includes(searchQuery.toLowerCase());
  });
  const displayedItems = filteredData.slice(startIndex, endIndex);
  // RESeting the filter
  const handleReset = () => {
    // Clear the tokens from local storage and redirect to the login page
    localStorage.removeItem('month');
    toast.success('Successfully Monthly Filter On Metrics!');
    window.location.reload()
  };

  // if the data is loading show a message
  if (isLoading) {
    return (
      <div className="loader-container">
        <img src={loaderImage} alt="Loading..." />
      </div>
    );
  }
  return (
    <>

      <Header showSidebar={showSidebar} onButtonClick={toggleSidebar} />
      {showSidebar && (
        <SideBar />
      )}

      <div className='dashboard'>

        <div className='progressCard'>
          <Row>

            <Col lg={12} md={12} sm={12}>

              <div className='tasks w-100' >
                <h5 className='float-start'>My List of Properties</h5>
                <div className='d-flex justify-content-end'>


                  <Form className="searchBar">
                    <FormControl type="text" placeholder="Search" onChange={handleSearch} />
                  </Form>
                </div>
                <Table className='table'>
                  <thead className='underline'>
                    <th>Image</th>
                    <th>Property</th>
                    <th>Area</th>
                    <th>County</th>
                  </thead>
                  <tbody>
                    {displayedItems.map((listApartment, index) =>
                      <tr>
                        <td>
                          {selectedApartmentImage[listApartment.id] && selectedApartmentImage[listApartment.id] ?
                            <Image src={selectedApartmentImage[listApartment.id]} className="imageTableDashboard" alt={index} style={{ width: "70px", height: "47px" }}></Image> :
                            <Image src={DefaultImage} className="imageTableDashboard" style={{ width: "70px", height: "47px" }}></Image>
                          }

                        </td>
                        <td>
                          <Link to={`/selected-property/${listApartment.id}`}>
                            {listApartment.name}
                          </Link>
                        </td>
                        <td>
                          {listApartment.location}

                        </td>
                        <td>
                          {listApartment.county}

                        </td>

                      </tr>
                    )}


                  </tbody>
                </Table>
                <div className="pagination">
                  <button onClick={handlePrevPage} disabled={currentPage === 0}>
                    &lt;
                  </button>
                  <span>{`${startIndex + 1}-${endIndex} of ${landlordApartment.length}`}</span>
                  <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                    &gt;
                  </button>
                </div>

              </div>
            </Col>
          </Row>
        </div>

        {/* final table that has graphs*/}
        {/* <TableJs /> */}
      </div >
      <footer className='footer'>
        &copy; {currentYear} Kodi
        <div className="footers_items">
          <Link to='https://jumuisha.co.ke/kodi' className='text-decoration-none '>
            <span className='me-3 text-muted'>About</span>
            <span className='me-3 text-muted'>Suport</span>
            <span className='text-muted'>Contact Us</span>
          </Link>

        </div>
      </footer>
    </>
  )
}

export default Dashboard